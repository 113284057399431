.rate-products-container {
    padding: 20px;
    margin: auto;
    width: 80%;
  }
  
  .rate-product {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .product-info {
    flex: 1;
    display: flex;
    align-items: center;
  }
  
  .product-info img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    margin-right: 20px;
    border-radius: 5px;
  }
  
  .product-info h3 {
    font-size: 18px;
  }
  
  .rating-section {
    flex: 2;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .star-rating {
    display: flex;
    margin-bottom: 10px;
  }
  
  .star {
    font-size: 24px;
    cursor: pointer;
    color: var(--color-tertiary-tint3);
  }
  
  .star.filled {
    color: var(--color-secondary);
  }
  
  .rating-section textarea {
    width: 100%;
    height: 100px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  
  .submit-all {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #008cba;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .submit-all:hover {
    background-color: #007bb5;
  }
  
  @media (max-width: 768px) {
    .rate-products-container {
        padding: 20px;
        margin: auto;
        width: 90%;
      }

    .rate-product {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .product-info {
      width: 100%;
      margin-bottom: 10px;
    }
  
    .product-info h3 {
      margin-bottom: 10px;
    }
  
    .star-rating {
      width: 100%;
      justify-content: space-between;
      margin-bottom: 10px;
    }
  
    .rating-section {
      width: 100%;
    }
  }
  