.logisticsOrderDetails {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    margin: auto;
}

.dataDetails {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.setAside {
    width: 40%;
    color: var(--color-primary-tint3);
}

.setDetail {
    align-self: right;
    font-weight: bold;
    text-transform: capitalize;
    color: var(--color-primary);
    padding: 1rem;
}

.setTimeDetail {
    align-self: right;
    font-weight: bold;
    text-transform: capitalize;
    color: var(--color-secondary);
    padding: 1rem;
}