.profile {
    display: flex;
    /* flex-direction: column; */ /* Remove this line to make them appear side by side */
    width: var(--container-width-lg);
    justify-content: space-around;
    margin: auto;
    color: var(--color-tertiary);
    border: 10px solid var(--color-white);
    border-radius: 10px;
  }
  .profilemenu {
    width: 30%;
    background-color: var(--color-white);
    border-radius: 10px;
    margin-right: 10px;
  }

.profback {
    width: 100%;
    border-radius: 10px;
    height: 200px;
    object-fit: cover;
}

.profimg {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 50px;
    margin-top: -60px;
    border: 5px solid var(--color-secondary-shade1)
}

.profName {
    color: var(--color-tertiary);
}

.manuopt {
    font-weight: bold;
    padding: 10px;
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: left;
    color: var(--color-tertiary);
    border-bottom: 1px solid var(--color-tertiary-tint1);
}

.subopt {
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.manuopt1 {
    padding: 12px;
    width: 100%;
    /* flex: 1 0 auto; */
    color: var(--color-tertiary);
    margin-left: 10px;
    text-align: left;
    /* font-weight: bolder; */
}

.menuitemhere {
    display: flex;
    justify-content: space-between;
    width: 90%;
    /* align-content: bottom; */
    margin-top: 90px;
    color: var(--color-primary);
    margin-bottom: 20%;
}

.profileproduct {
    width: 70%;
    background-color: var(--color-white);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; 
    list-style-type: none;
    padding: 20px; /* Add padding to space items */
    align-content: center; 
    margin-top: 2px;
  }

.eachproduct {
    width: 20%; /* Adjust the width as needed */
    height: 250px;
    border-radius: 10px;
    margin: 10px;
    display: flex;
    flex-direction: column;
    padding: 5px;
    align-content: center;
    box-shadow: 1px 2px var(--color-tertiary-tint3);
    background-color: var(--color-white);
    border: 1px solid var(--color-tertiary-tint3);
  }

.scale {
    font-size: x-small;
}

/* .eachproduct:hover {
    border: 2px solid var(--color-secondary-shade1);
} */

.productImage {
    width: 100%; /* Adjust the width as needed */
    height: 120px;
    object-fit: cover;
    align-self: center;
    margin: auto;
  }

.farmname {
    font-size: 10px;
    align-self: flex-start;
}

.productname {
    font-size: medium;
    font-weight: bold;
    align-self: flex-start;
}

.ratingc {
    display: flex;
    color: var(--color-secondary-shade1);
    justify-content: flex-start;
    align-content: center;
}

.rating {
    font-size: 8px;
    color: var(--color-tertiary);
    align-self: center;
}

.editoptions {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    margin: 10px 10px;
    color: var(--color-white);
}

.editproduct {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--color-primary);
    padding: 3px;
    border-radius: 5px;
    width: 50px;
    color: var(--color-white);
    font-size: 10px;
    margin: 3px;
}

.deletproduct {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--color-danger-variant);
    padding: 3px;
    border-radius: 5px;
    color: var(--color-white);
    width: 50px;
    font-size: 10px;
    margin: 3px;
}

.uploadproduct {
    position: Fixed;
    text-align: center;
    background-color: var(--color-primary);
    border-radius: 50%;
    width: 80px;
    height: 80px;
    padding: 5px;
    color: var(--color-white);
    right: 40px; 
    bottom: 80px;
    font-size:small
  }

/* CSS for your component */
.locations {
    display: flex;
    flex-direction: column; 
    margin-top: 0px;
  }

.eachLocation {
    display: flex;
    justify-content: space-between;
    align-self: start;
    align-items: center;
    margin: 10px 20px;
}

.locationTexts {
    display: flex;
    flex-direction: column;
}
.formContainer {
    max-width: 550px;
    width: 95vw;
    background-color: var(--color-primary);
    margin: 40px auto;
    color: rgb(255, 255, 255);
    padding: 20px;
    border-radius: 10px;
}

.post_bts {
    display:flex;
    justify-content: space-around;
}

.post_bt {
    background-color: var(--color-secondary);
    color: var(--color-grey);
    width: 47%;
    padding: 10px 50px;
    border-radius: 10px;
    margin-top: 15px;
    cursor: pointer;
    font-weight: bold;
}
  


@media (max-width:1300px) {
    /* .profile {
        margin: 1px;
    } */
    .profilemenu {
        display: none;
        visibility: hidden;
    }

    .profileproduct {
        width: 100%;
        padding: 2px
    }

    .profimg {
        width: 5px;
        height: 5px;
        
    }

    .eachproduct {
        height: fit-content;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        padding: 1px;
        margin: 2px;
        align-content: center;
        box-shadow: 1px 2px var(--color-tertiary-tint2);
        max-width: 200px;
        background-color: var(--color-white);
    }

    .productImage {
        width: 80%;
        /* height: 120px; */
        object-fit: cover;
        align-self: center;
        margin: auto;
    }
}