.edit-logistics,
.edit-company {
  background-color: var(--color-primary-shade3);
  width: 90%;
  margin: auto;
  padding: 20px;
  border-radius: 10px;
}

.edit-logistics-form,
.edit-company-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  font-weight: bold;
  margin-bottom: 5px;
}

.form-group input,
.form-group select {
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 100%;
}

.custom-file-input {
  border: none;
}

.image-preview-container {
  margin-top: 10px;
}

.image-preview img {
  max-width: 100px;
  border-radius: 5px;
}

.submit-button {
  background-color: var(--color-primary);
  color: var(--color-white);
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 50px;
}

.submit-button:hover {
  background-color: var(--color-primary-shade2);
}

.store-hour {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.store-hour span {
  width: 100px;
  flex-shrink: 0;
}

.store-hour input {
  flex: 1;
  margin: 5px;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--color-white);
  z-index: 9999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.5em;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .edit-logistics,
  .edit-company {
    width: 100%;
    padding: 10px;
  }

  .store-hour {
    flex-direction: column;
    align-items: flex-start;
  }

  .store-hour span,
  .store-hour input {
    width: 100%;
    margin-bottom: 5px;
  }
}
