.signContainer {
  max-width: var(--container-width-md);
  min-width: var(--container-width-sm);
  min-height: 100vh;
  display: flex;
  margin: auto;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.searchContainer{
  width: 100%;
  align-items: center;
}

.targets {
  display: flex;
  justify-content: center;
  width: var(--container-width-sm);
}

.signutarget {
  flex-basis: 50%;
  align-items: center;
  background-color: var(--color-primary);
  padding: 120px 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: var(--container-width-sm);
}

.smallestw {
  font-size: xx-small;
}

.signufform {
  flex-basis: 50%;
  background-color: var(--color-white);
}

.signueach {
  justify-content: space-between;
  color: var(--color-white);
  margin-top: 50px;
  border: 2px solid var(--color-white);
  min-width: 80%;
  align-content: center;
  padding: 10px;
}

.signueach1 {
  color: var(--color-white);
  display: flex;
  justify-content: space-between;
}

.already {
  color: var(--color-white);
  margin: 30px;
}

.signlink {
  color: var(--color-secondary);
}

.lohosm {
  width: 20%;
  justify-content: start;
}

.signutargetm {
  display: none;
  visibility: hidden;
}

.signtext {
  display: none;
  visibility: hidden;
}

.form_inp {
  border-bottom: 1px solid var(--color-tertiary);
  border-radius: 0;
}

.sign_form {
  width: 100%;
  margin: auto;
}

.sign_bt {
  width: 95%;
  padding: 10px;
  background-color: var(--color-secondary);
  border-radius: 3px;
  font-weight: bolder;
  color: var(--color-white);
  margin: auto;
}

.post_sign {
  align-self: center;
  margin: auto;
  width: 90%;
}

.already1 {
  font-size: small;
  padding: 10px;
  margin-bottom: 40px;
}

.signalt {
  color: var(--color-secondary);
  font-weight: bold;
}

.tandcConatiner {
  text-align: center;
  width: 90%;
}

.tandc {
  color: var(--color-primary);
  text-decoration: underline;
}

.password-input-container {
  display: flex;
  justify-content: space-between;
}

.showbutton {
  padding: 0.1px;
  margin: 0.1px;
}

@media (max-width: 870px) {
  .signufform {
    flex-basis: 100%;
  }
  .signutarget {
    display: none;
    visibility: hidden;
  }

  .signtext {
    display: block;
    visibility: visible;
    color: var(--color-primary);
  }

  .targets {
    display: flex;
    visibility: visible;
    align-items: center;
    justify-content: center;
    width: 95%;
    padding: 5px;
  }

  .signutargetm {
    display: flex;
    visibility: visible;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: auto;
    padding: auto;
    /* margin: 10px 40px; */
  }

  .signueachm {
    min-width: 47px;
    max-width: 31%;
    color: var(--color-primary);
    border: 1px solid var(--color-primary);
    border-radius: 5px;
    height: 70px;
    margin: 10px 10px;
  }
  .selectEach {
    width: 100%;
    color: var(--color-primary);
    border: 1px solid var(--color-primary);
    border-radius: 5px;
    min-height: 45px;
    max-height: 60px;
    margin: 3px 3px;
  }

  .signueach1ms {
    width: 100%;
    color: var(--color-white);
    border: 1px solid var(--color-primary);
    border-radius: 5px;
    /* min-height: 45px; */
    height: 70px;
    background-color: var(--color-primary);
  }

  .signutargetm {
    text-align: center;
  }

  .targetIcon {
    padding: 1px 10px;
    color: var(--color-primary);
    font-size: large;
    font-weight: medium;
  }

  .targetText {
    font-size: 10px;
  }

  .sign_form {
    width: 100%;
    margin: auto;
  }
}
