/* src/components/RegistrationProgress.css */
.registration-progress {
    margin: 20px 0;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .progress-bar {
    background-color: #f0f0f0;
    border-radius: 5px;
    overflow: hidden;
  }
  
  .progress {
    height: 20px;
    border-radius: 5px;
  }
  
  .progress.red {
    background-color: red;
  }
  
  .progress.yellow {
    background-color: yellow;
  }
  