.loading {
  height: 70vh;
}
.loading-spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top: 4px solid var(--color-primary);
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
  margin: 0 auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.prodPag {
  /* width: 100%; */
  display: flex;
  flex-direction: column;
}
.userpages {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.sideNavs {
  display: flex;
  flex-direction: column;
  width: 5%;
  align-content: flex-start;
  justify-content: center;
  background-color: var(--color-tertiary-tint3);
  height: 100vh;
  /* height: 100%; */
}

.emptyPage {
  font-size: larger;
  width: 70%;
  margin: auto;
  font-weight: bolder;
  /* position: absolute; */
  margin-top: 10vh;
}

.searchField {
  width: 50%;
  padding: 10px;
  border: 2px solid var(--color-tertiary-tint3);
  border-radius: 20px;
  /* border-color: var(--color-tertiary-tint1); */
  align-self: center;
}

.listProducts {
  width: 80%;
  background-color: var(--color-white);
  display: flex;
  justify-content: space-between;
  align-content: center;
  flex-wrap: wrap;
  justify-content: center;
  list-style-type: none;
  margin: auto;
  color: var(--color-tertiary);
}

.listStores {
  width: 80%;
  background-color: var(--color-white);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-right: 8%;
  color: var(--color-tertiary);
}

.addToCart {
  display: flex;
  background-color: var(--color-primary);
  border-radius: 5px;
  color: var(--color-white);
  align-content: center;
  justify-content: space-between;
  padding: 7px 25px;
}
.profileproduct {
  width: 70%;
  background-color: var(--color-white);
  display: flex;
  justify-content: space-between;
  /* padding: 20px; */
  align-content: center;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  list-style-type: none;
}

.eachStore {
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-content: space-around;
  background-color: var(--color-primary);
  border-radius: 10px;
  margin: 10px 0;
  color: var(--color-white);
}

.storeDistance {
  display: flex;
  justify-content: flex-end;
  align-content: felx-end;
}

.storeData {
  align-content: start;
  justify-content: start;
}

.eachproduct {
  width: 200px;
  height: 270px;
  border-radius: 10px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  align-content: center;
  box-shadow: 1px 2px var(--color-tertiary-tint3);
  background-color: var(--color-white);
  border: 1px solid var(--color-tertiary-tint3);
  color: var(--color-tertiary);
}

.avail {
  font-size: 8px;
  color: var(--color-tertiary);
  align-self: start;
}

.loading {
  height: 70vh;
}

.scale {
  font-size: smaller;
  font-weight: bold;
  align-self: flex-start;
}

.backgroundContainer {
  background-color: var(--color-tertiary-tint3);
}

.productImage {
  width: 150px;
  height: 120px;
  object-fit: cover;
  align-self: center;
  margin: auto;
}

.storeImage {
  width: 100px;
  height: 100px;
  object-fit: cover;
  align-self: center;
  margin: auto;
}
.storeInfo {
  display: flex;
  flex-direction: row;
  justify-content: left;
  margin: -3% 10% -1% 10%;
}

.storSearchField {
  position: absolute;
  align-self: center;
  width: 80%;
  margin: 5% 10%;
}

.storeAvatar {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 50%;
  border: 5px solid var(--color-white);
}
.storeInfo1 {
  display: flex;
  flex-direction: column;
  justify-content: left;
  margin: 4% 0;
}

.farmname {
  font-size: 10px;
  align-self: flex-start;
}

.productname {
  font-size: medium;
  font-weight: bold;
  align-self: flex-start;
}

.pagination {
  margin-bottom: 100px;
}

.mfooter {
  display: none;
}

.footer {
  display: flex;
}

.categoryButton {
  width: fit-content !important;
  border-radius: 20px;
  /* padding: 10px;
  margin: 2px 5px; */
  padding: 10px 15px;
  margin: 5px;
  background-color: var(--color-primary);
  color: var(--color-white);
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.categoryButton:hover {
  background-color: var(--color-tertiary); /* Hover effect */
}

.categoryButton.selected {
  background-color: var(--color-secondary-shade1); /* Selected category color */
  color: white; /* Text color for better contrast */
}

.logDetCont {
  margin: auto;
  display: flex;
  justify-content: left;
  width: 80%;
  align-items: center;
}

.logimg {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 50px;
  border: 5px solid var(--color-secondary-shade1);
  margin-right: 10px;
}

.logisticsForm {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin: auto;
  padding: 10px;
}

.sendReceive {
  background-color: var(--color-primary);
  color: var(--color-white);
  border-radius: 30px;
  padding: 10px;
}

.locationCont {
  margin: auto;
  display: flex;
  justify-content: space-around;
  width: 100%;
  flex-wrap: wrap;
}

.eachInput {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 7px;
}

.inputField {
  width: 60%;
  padding: 5px;
  border-radius: 5px;
  border: 2px solid var(--color-tertiary-tint3);
}

.label {
  text-align: left;
}

.log {
  font-weight: bolder;
  color: var(--color-white);
  margin: auto;
  margin-right: 0px;
  margin-top: 10px;
  width: 60%;
}

.inst {
  font-size: smaller;
  color: var(--color-grey);
}

@media (max-width: 1100px) {
  .profilemenu {
    display: none;
    visibility: hidden;
  }

  .profileproduct {
    width: 100%;
  }

  .profimg {
    width: 5px;
    height: 5px;
  }

  .eachproduct {
    width: 42%;
    height: fit-content;
    border-radius: 10px;
    margin: 10px;
    display: flex;
    flex-direction: column;
    padding: 20px;
    align-content: center;
    box-shadow: 1px 2px var(--color-tertiary-tint2);
    max-width: 150px;
    background-color: var(--color-white);
  }

  .productImage {
    width: 100%;
    object-fit: cover;
    align-self: center;
    margin: auto;
  }

  .searchField {
    width: 80%;
  }
  .listProducts {
    width: 95%;
  }

  .listStores {
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-bottom: 70px;
  }
  .addToCart {
    padding: 2px 10px;
  }
  .sideNavs {
    visibility: hidden;
  }
  .mfooter {
    display: flex;
    position: fixed;
    bottom: 0;
    margin: auto;
    width: 100%;
    background-color: var(--color-white);
    padding: 10px;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.2);
    justify-content: center;
  }
  .footer {
    display: none;
  }

  .logisticsForm {
    width: 90%;
  }

  .log {
    margin: auto;
  }
}
