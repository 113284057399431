/* NotificationComponent styles */
.dashboard {
    margin: 20px;
    padding: 20px;
    border: 1px solid var(--color-white);
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .dashboard h1 {
    margin-bottom: 16px;
    color: var(--color-tertiary);
  }
  
  .ulContainer {
    list-style: none;
    padding: 0;
  }

.notificationLinks {
    padding: 50px;
}
  
  .ulContainer > li {
    border-bottom: 1px solid var(--color-white);
    padding: 12px 0;
  }
  
  .ulContainer > li:last-child {
    border-bottom: none;
  }
  
  .ulContainer > li > p {
    margin: 0;
    font-size: 16px;
    color: var(--color-tertiary);
  }
  
  /* Unread count badge styles */
  .unread-badge {
    background-color: var(--color-secondary);
    color: var(--color-white);
    font-size: 12px;
    padding: 4px 8px;
    border-radius: 50%;
    margin-left: 6px;
  }
  
  /* Link styles */
  a {
    text-decoration: none;
    color: inherit;
  }
  
  a:hover {
    text-decoration: underline;
    color: var(--color-primary-tint2);
  }
  
  .notification-item {
    border-bottom: 1px solid var(--color-white);
    padding: 12px 0;
  }
  
  /* Flexbox for notification content */
  .notification-content {
    display: flex;
    justify-content: space-between;
  }
  
  /* Align message to the left and date to the right */
  .notification-message {
    margin: 0;
    font-size: 16px;
    text-align: left;
    flex: 1; /* Take remaining space */
  }
  
  .notification-date {
    margin: 0;
    font-size: 8px;
    text-align: right;
    flex-shrink: 0; /* Prevent shrinking */
  }

  hr.solid {
    border-top: 3px solid var(--color-tertiary-tint2);
  }