.dashboard {
  display: flex;
  justify-content: flex-start;
  align-content: flex-start;
  flex-direction: column;
  width: 100%;
}

.dashboardTitle {
  justify-content: left;
  margin-top: 0;
}

.topDashboard {
  /* border: 1px solid var(--color-tertiary-shade3); */
  display: flex;
  justify-content: space-between;
}

.eachDash {
  min-width: 150px;
  min-height: 150px;
  background-color: var(--color-tertiary-tint3);
  padding: 0.3rem;
  border-radius: 10px;
  margin: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: auto;
}

.singleDash {
  display: flex;
  justify-content: baseline;
  text-align: center;
  margin: auto;
}

.spaceB {
  display: flex;
  justify-content: space-between;
}
.smallest {
  font-size: xx-small;
}

.moneyCont {
  display: flex;
  margin: 10px;
  color: var(--color-white);
  font-weight: bold;
}

.moneyCont1 {
  width: 45%;
  margin: auto;
  background-color: var(--color-secondary);
  border-radius: 10px;
  padding: 15px;
  color: var(--color-white);
}

.extraSmall {
  font-size: xx-small;
}

.stacked {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.moneyCont2 {
  width: 45%;
  margin: auto;
  border-radius: 10px;
  background-color: var(--color-primary);
  padding: 15px;
  color: var(--color-white);
}

.middleDash {
  height: 300px;
  padding: 100px auto;
}

@media (max-width: 1300px) {
  .topDashboard {
    flex-direction: column;
  }
  .eachDash {
    width: 95%;
    margin: 4px;
  }

  .moneyCont {
    margin: 5px auto;
    min-width: 90%;
  }
}
