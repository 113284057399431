.chatContainer {
  margin-top: 2rem;
}

.Chat {
  position: relative;
  display: grid;
  grid-template-columns: 22% auto;
  gap: 1rem;
  padding: 0rem 1rem;
}

.Left-side-chat {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.Chat-container {
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
  background: var(--color-tertiary-tint2);
  border-radius: 1rem;
  padding: 0.7rem;
  height: auto;
  min-height: 80vh;
  overflow: scroll;
}

.Chat-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.placeholder {
  margin-top: 45vh;
}

.Right-side-chat {
  display: flex;
  flex-direction: column;
  /* gap: 1rem; */
}
.conversation:hover {
  background: var(--color-tertiary-shade3);
  cursor: pointer;
}

.conversation {
  border-radius: 0.5rem;
  padding: 10px;
}

.conversation > div {
  position: relative;
}

.online-dot {
  background-color: var(--color-primary-tint1);
  border-radius: 50%;
  position: absolute;
  left: 2rem;
  width: 1rem;
  height: 1rem;
}

.unread {
  background-color: var(--color-secondary-tint1);
  border-radius: 2rem;
  position: absolute;
  right: 2rem;
  text-align: center;
  align-content: center;
  font-size: smaller;
}

.chatbox-empty-message {
  display: flex;
  align-self: center;
  justify-content: center;
  font-size: 20px;
}

.ChatBox-container {
  background: var(--color-tertiary-tint2);
  border-radius: 1rem;
  display: grid;
  grid-template-rows: 14vh 60vh 13vh;
}

.chat-header {
  padding: 1rem 1rem 0rem 1rem;
  display: flex;
  flex-direction: column;
}

.chat-body {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1.5rem;
  overflow: scroll;
}

.message {
  background: var(--color-secondary);
  color: var(--color-white);
  padding: 0.7rem;
  border-radius: 1rem 1rem 1rem 0;
  max-width: 28rem;
  width: fit-content;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.message > span:nth-child(2) {
  font-size: 0.7rem;
  color: var(--color-primary);
  /* color: var(--textColor); */
  align-self: end;
}

.own {
  align-self: flex-end;
  border-radius: 1rem 1rem 0 1rem;
  background: linear-gradient(
    98.63deg,
    var(--color-primary-tint3) 0%,
    var(--color-primary-tint3) 100%
  );
}

.chat-sender {
  background: var(--color-white);
  display: flex;
  justify-content: space-between;
  height: 3.5rem;
  align-items: center;
  gap: 1rem;
  padding: 0.8rem;
  border-radius: 1rem;
  align-self: end;
}
.chat-sender > input {
  height: 70%;
  background-color: var(--color-tertiary-tint3);
  border-radius: 0.5rem;
  border: none;
  outline: none;
  flex: 1;
  font-size: 14px;
  padding: 0px 15px 0px 15px;
}
.chat-sender > div:nth-of-type(1) {
  /* background: var(--color-tertiary-tint3); */
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  cursor: pointer;
}
.chat-sender > div {
  height: 70%;
  padding: 0px 15px 0px 15px;
}

.flexIt {
  gap: 0.4rem; 
  display: flex;
  align-items: center;
  justify-content: center
}

.followerImage {
  max-width: 3.2rem;
  max-height: 3.2rem;
  border-radius: 50%;
  margin-right: 10px;
  position: absolute;

  left: 4rem;
}

.topChatCont {
  display: flex;
}

.followerImage1 {
  max-width: 3.2rem;
  max-height: 3.2rem;
  border-radius: 50%;
  margin-right: 10px;

  left: 17rem;
}

.name {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: 4rem;
}

.name > span:nth-of-type(1) {
  font-weight: bold;
}

.send-button {
  padding: 2rem 3rem; /* Adjust padding as needed */
  background-color: var(--color-primary); /* Change the background color */
  color: var(--color-white);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1.1rem;
  text-align: center;
  transition: background-color 0.3s ease-in-out;
}

@media screen and (max-width: 768px) {
  .Chat {
    grid-template-columns: 16% auto;
  }
  .follower-conversation > div > .name {
    display: none;
  }
}
