.detailPageContainer {
  background-color: var(--color-tertiary-tint2);
}

.detailPage {
  display: flex;
  width: 80%;
  margin: auto;
  justify-content: space-between;
  /* padding: 0 100px; */
  background-color: var(--color-tertiary-tint2);
}

.detailContainer {
  display: flex;
  background-color: var(--color-tertiary-tint2);
}

.wholeProductCentent {
  display: flex;
  flex-direction: column;
  margin: 10px auto;
  background-color: var(--color-white);
  padding: 20px;
  border-radius: 20px;
  min-width: 50vw;
  max-width: 55vw;
}

.topContCont {
  display: flex;
  justify-content: space-between;
}

.topContainer {
  text-align: left;
  /* margin: 5px; */
}

.h1NoPad {
  padding: 0;
  margin-bottom: 0;
}

.topUnder {
  font-size: 9px;
  font-weight: bold;
  color: var(--color-primary);
  margin-top: 0;
  padding-top: 0;
}

.chatButton {
  padding: 10px;
  color: var(--color-white);
  background-color: var(--color-primary-tint1);
  border-radius: 20px;
  font-weight: bold;
  margin: 10px;
}

.productDetail {
  display: flex;
  /* width: 120vw; */
}

.mainImage {
  width: 300px;
  height: 300px;
  object-fit: cover;
}

.otherImages {
  width: 50px;
  height: 50px;
  object-fit: cover;
  margin: 5px;
}

.productBody {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0 10px;
}

.productBody > h3 {
  color: var(--color-primary);
}

.productStock {
  padding: 3px 10px;
  background-color: var(--color-primary-tint3);
  border-radius: 15px;
  color: var(--color-white);
  margin: 10px 0;
  /* font-weight: bold; */
}

.productDescription {
  width: 100%;
  height: 120px;
  overflow-y: auto;
  text-align: justify;
  padding: 0 10px;
}

.cartControl {
  display: flex;
  justify-content: space-between;
}

.quantity-control {
  padding: 10px;
  margin: 1px 0;
  height: 40px;
  align-content: left;
}

.quantity-control > button {
  color: var(--color-white);
  background-color: var(--color-primary-tint3);
  font-size: larger;
  font-weight: bolder;
  border-radius: 20px;
  padding: 10px 15px;
  margin: 1px 5px;
}

.cartButton {
  padding: 1px 20px;
  border-radius: 20px;
  background-color: var(--color-secondary);
  color: var(--color-white);
  height: 30px;
  font-weight: bolder;
  cursor: pointer;
  margin: 20px auto;
}

.shareButtons {
  display: flex;
  flex-direction: column;
}

.report {
  color: var(--color-primary);
  background-color: var(--color-white);
}

.shareB {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 250px;
}

.shareB > span {
  margin-top: 4px;
}

.eachButton {
  padding: 5px;
  margin: 0 2px;
  border-radius: 25px;
  background-color: var(--color-white);
  color: var(--color-primary);
}

.cart-section {
  background-color: var(--color-white);
  width: 30%;
  margin: 10px;
  border-radius: 20px;
}


.cart-section > h3 {
  color: var(--color-primary);
  margin: 20px 20px 2px;
}

.empty-cart {
  color: var(--color-primary-tint3);
}

.relatedh3 {
  color: var(--color-white);
  float: start;
}

.relatedProduct {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: center;
  width: 80%;
  margin: auto;
}

ul {
  list-style-type: none;

}
.detailCart {
  display: flex;
  padding: 0;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 10px;
}

.detailCartEach {
  align-items: center;
  font-size: small;
  margin: 0px;
  border: 0px solid var(--color-secondary);
}

.deatilCartCunt {
  display: flex;
  color: var(--color-primary);
  padding: 10px 10px;
  justify-content: center;
}

.deatilCartCunt >h5 {
  margin-right: 10px;
  padding: 10px;

}

.detailCartTotal {
  margin-bottom: 15px;
  margin: auto;
}

@media (max-width: 1100px) {
  .detailPage {
    display: flex;
    flex-direction: column;
  }

  .wholeProductCentent {
    min-width: 80vw;
  }

  .mainImage {
    width: 110px;
    height: 110px;
    object-fit: cover;
  }

  .otherImages {
    width: 25px;
    height: 25px;
    object-fit: cover;
    margin: 2.5px;
  }

  .productDescription {
    width: 100%;
    height: 90px;
    overflow-y: auto;
    text-align: justify;
    padding: 0 5px;
    font-size: small;
  }

  .chatButton {
    padding: 5px;
    color: var(--color-white);
    background-color: var(--color-primary-tint1);
    border-radius: 20px;
    font-weight: bold;
    font-size: small;
    margin: 5px;
  }
  .cartControl {
    display: flex;
    padding: 1px;
    margin: 1px;
    flex-direction: column;
    justify-content: space-between;
  }

  .quantity-control {
    padding: 4px;
  }
  .cartButton {
    padding: 1px 15px;
  }

  .cart-section {
    width: 100%;
    margin: 10px auto;
  }
}
