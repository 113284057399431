.cartContainer > tr,
td,
th {
  padding: 10px;
  margin: auto;
  border: none;
}

.cartContainer {
  width: 80%;
  border: none;
}

.trDiv {
  display: flex;
}

.detailCart1 {
  display: flex;
  padding: 0;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 10px;
  min-width: 700px;
}

.detailCart {
  display: flex;
  padding: 0;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 10px;
}

.CartQuantityCont {
  width: 90px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 120px;
  font-size: small;
  margin: auto;
  border: 0px solid var(--color-secondary);
}

.CartQuantityCont > button {
  background-color: var(--color-primary);
  border-radius: 3px;
  color: var(--color-white);
  margin: auto;
  align-items: center;
  width: 20px;
  height: 20px;
  padding: 2px;
}

.CartQuantityCont > h3 {
  margin: 10x;
}

.cartBasket {
  color: var(--color-secondary);
}

.deatilCartCunt {
  display: flex;
  color: var(--color-primary);
  padding: 10px 10px;
  justify-content: space-between;
}

.cartLog {
  display: inline-block;
  background-color: var(--color-primary);
  color: var(--color-white);
  border-radius: 2px;
  padding: 3px;
}

.detailCartEach1 {
  min-width: 150px;
  border: 0px solid var(--color-primary);
}

.detailCartEach1,
.CartQuantityCont1 {
  padding: 1px;
  margin: 1px;
}

.cartLogCont {
  display: flex;
  flex-direction: column;
  min-width: 700px;
  width: 100%;
  justify-content: space-between;
}

.deliveryOption {
  display: flex;
  justify-content: space-between;
  min-width: 700px;
  width: 100%;
  min-width: 300px;
  margin: 2px;
}

.options {
  background-color: var(--color-white);
  border-radius: 3px;
  min-width: 400px;
  width: 50%;
  border: 2px solid var(--color-secondary);
  padding: 3px;
}

.selectedVehicle {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.selectedVehicle > img {
  width: 200px;
}
@media (max-width: 1300px) {
  .farmTr {
    display: flex;
    flex-direction: column;
  }
  .detailCart {
    min-width: 100%;
  }

  .detailCartEach {
    min-width: 50px;
  }
  .cartLogCont {
    display: flex;
    flex-direction: column;
    min-width: 100%;
    width: 100%;
    justify-content: space-between;
  }

  .deliveryOption {
    display: flex;
    justify-content: flex-end;
    /* min-width: 95%; */
    width: 99%;
    min-width: 200px;
    margin: 2px;
  }

  .options {
    background-color: var(--color-white);
    border-radius: 3px;
    min-width: 50%;
    width: 50%;
    border: 2px solid var(--color-secondary);
    padding: 3px;
  }
}
