.edit-store {
  background-color: var(--color-primary-shade3);
  width: 90%;
  margin: 0 auto;
  padding: 10px;
}

.edit-store-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.form-group label {
  margin-bottom: 5px;
}

.form-group input,
.form-group select {
  padding: 8px;
  font-size: 16px;
}

.image-preview-container {
  margin-top: 10px;
}

.image-preview img {
  max-width: 100px;
  max-height: 100px;
  margin-right: 10px;
}

.store-hours {
  margin: 10px;
}

.store-hours-item {
  display: flex;
  flex-direction: column;
  margin: 10px;
}

.store-hours-item label {
  margin: 5px;
}

.store-hours-item-selectors {
  display: flex;
  align-items: center;
  margin: 10px;
}

.store-hours-item-selectors select,
.store-hours-item-selectors input[type="time"] {
  margin: 10px;
  padding: 8px;
  font-size: 16px;
}

.store-hours-item-remove {
  margin-top: 5px;
}

.store-hours button {
  padding: 8px 12px;
  margin: 10px;
  background-color: var(--color-secondary);
  color: white;
  border: none;
  cursor: pointer;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.7);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.loading-spinner {
  border: 4px solid rgba(255, 255, 255, 0.2);
  border-top: 4px solid #fff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 0.8s linear infinite;
}

.remove {
  width: 90px;
  margin: 5px;
  background-color: var(--color-secondary);
  border-radius: 5px;
  padding: 10px;
  color: var(--color-white);
}

.addhour {
  width: 100%;
  /* margin: 5px; */
  background-color: var(--color-tertiary-shade2);
  border-radius: 5px;
  padding: 10px;
  color: var(--color-white);
}

.updatestore {
  width: 100%;
  /* margin: 5px; */
  margin-bottom: 50px;
  background-color: var(--color-primary);
  border-radius: 5px;
  padding: 10px;
  color: var(--color-white);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 768px) {
  .store-hours-item-selectors {
    flex-direction: column;
  }

  .store-hours-item-selectors select,
  .store-hours-item-selectors input[type="time"] {
    margin-right: 0;
    margin-bottom: 10px;
  }
}
