.foot {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: center;
  background-color: #fff;
}

.eachFoot {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 0px 7px;
  margin: auto;
  color: var(--color-tertiary);
}

.eachFoot p {
  margin-top: 2px;
  font-size: x-small;
  color: var(--color-tertiary);
}

.eachFoot.selected {
  color: green;
}

.eachFoot p.selected {
  color: green;
}
