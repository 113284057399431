.order-list-view-container{
    width:100%;
    height: 100%;
  
}
.order-list-view-container h2{
    text-align: left;
}
.order-list-view-container-nav{
    display: flex;
    width:100%;
    border-bottom: 2px solid var(--color-tertiary-shade3);
    padding-bottom: .4rem;
    margin-top: 2rem;
}
.order-list-view-container-nav h3{
    margin-right: 3rem;
    color: var(--color-tertiary-shade2);
}
.order-list-view-container-search-bar{
    display: flex;
    align-items: center;
    height: 3rem;
    justify-content: space-between;
    color: var(--color-tertiary-shade2);
    margin-top: 1rem;
}
.order-list-view-container-search-bar-input{
    display: flex;
    align-items: center;
    width: 60%;
    border: 1px solid var(--color-tertiary-shade3);
    height: 100%;
    border-radius:4px ;
    padding: 0rem 1rem;
}
.order-list-view-container-search-bar-input-field{
    height: 2rem;
    width:80%;
    border: none;
    font-size: 1rem;
}
.order-list-view-container-search-bar-filter{
    display: flex;
    align-items: center;
    width: 18%;
    border-radius: 4px;
    padding: .5rem;
    border: 1px solid var(--color-tertiary-shade3);
    height: 100%;

}
.order-list-view-container-search-bar-export{
    display: flex;
    align-items: center;
    width: 18%;
    border-radius: 4px;
    border: 1px solid var(--color-tertiary-shade3);
    height: 100%;
    padding: .5rem;
}
.order-list-view-container-table{
    height: 100vh;
   
    width:100%;
    overflow-y: auto;
    margin-top: 1rem;
}
.order-list-table{
    width: 100%;
    border-collapse: collapse;
}
.order-list-table > tr, td, th{
    padding: 10px;
    margin: auto;
    border: none;
    border-collapse:collapse ;
    
    }
 
th{
    text-align: left;
    /* border: 1px solid red; */
    font-size: 16px;
    font-weight: 600;
    padding:1rem 0rem;
    border: none;
 
}
td{
    font-weight: 600;
    padding:.5rem .2rem;
    border: none;
    /* border: 1px solid red; */
}
.order-list-view-container-table-head{
    position: sticky;
    z-index: 20;
    top: 0rem;
    /* border-bottom: 2px solid var(--color-tertiary-shade3); */
    padding-bottom: 4rem;
    background-color: var(--color-white);
    bottom:1rem ;
   
}
.order-list-view-container-table-head-item-count{
    text-align: center;
}

.order-list-view-container-table-body-data-item-count{
    text-align: center;
}
.order-list-view-container-table-head-delivery-status{
    text-align: center;
}
.order-list-view-container-table-body-data-delivery-status{
    display: flex;
    justify-content: center;
    align-items: center;
}
.order-list-view-container-table-head-delivery-type{
    text-align: center;
}
.order-list-view-container-table-body-data-delivery-status div{
    font-weight: 600;
    border-radius:3px ;
    width:6rem;
    text-align: center;
    padding: .3rem .5rem;
    background-color: rgb(181, 157, 181);
}
.order-list-navigation{
    width: 100%;
    height: 3rem;
    border-radius: 10px;
    background-color: var(--color-tertiary-shade2);
    margin-top: 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
}
.order-list-navigation-btn{
    display: flex;
    align-items: center;


}
.order-list-navigation-btn-icon{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid var(--color-tertiary-shade3);
    height: 2rem;
    width: 3rem;
    padding:0 .5rem 0 .5rem;
    margin-left: 1rem;
}
.order-list-navigation-btn-icon2{
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 2rem;
    width: 3rem;
    border: 1px solid var(--color-tertiary-shade3);
    margin-left: 1rem;
}
.mobile-view{
    display: none;
}
@media(max-width:75em){
    .order-list-view-container-nav{
        font-size: 80%;
    }
    .order-list-view-container{
        font-size:80%;
    }
    th{
        font-size: 1rem;
    }
}

@media( max-width:40.25em){
    .order-list-view-container-nav{
        display: none;
    }
    .order-list-view-container-search-bar{
        display: none;
    }
    .order-list-view-container-table{
        display: none;
    }
    .order-list-navigation{
        display:none;
    }
    .mobile-view{
        display: block;
        height: 100%;
        width: 100%;
        overflow-y: auto;
    }
    .order-list-mobile-reference{
        /* height: 3rem; */
        border-bottom: 1px solid var(--color-tertiary-shade3);
        position: relative;
        padding-bottom:1rem ;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        font-size: 0.9rem;
        margin-bottom: 1rem;
    }
    .order-list-mobile-reference-date{
        color: var(--color-tertiary-shade3);
    }
    .order-list-mobile-reference-label{
        color: var(--color-tertiary-shade3);
        margin-top: .5rem;
    }
    .order-list-mobile-reference-status{
        color: var(--color-primary-shade2);
        position: absolute;
        bottom: 1rem;
        right: 0rem;
    }
}