.landin-main {
  /* background:url('../static/back.avif'); */
  position: relative;
  margin-top: 0;

  /* background-image:  */
  /* linear-gradient( */
  /* rgba(255, 255, 255, 0.85),  */
  /* rgba(255,164,0, 0.45), */
  /* rgba(57,120,70, 0.45) , */
  /* rgba(0,0,0,0.95) */
  /* ), */
  /* url('../static/back.jpg'); */
  /* background-size: cover; */
  /* background-repeat: no-repeat; */
  /* width:100%;  */
  /* background-attachment: fixed; */

  /* height: 170vh; */
}

/* Header styles */
.headerContainer {
  width: 100%;
  background-color: var(--color-white);
  z-index: 50;
  height: 80px;
  position: fixed;
  top: 0;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1); /* Add shadow for effect */
}

.headerSubContainer {
  width: 80%;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative; /* Positioning relative for desktop adjustments */
}

.headerLogo img {
  width: 80px;
  height: 52px;
}

/* Mobile Navigation */
.nav {
  position: fixed;
  width: 70%;
  height: 100vh;
  background-color: white;
  top: 5rem; /* equivalent to top-20 */
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 2.5rem; /* equivalent to pt-10 */
  transition: left 0.3s ease;
  z-index: 40; /* Ensure it overlays properly */
}

.nav.hidden {
  left: -100%;
}

.nav-item {
  margin-left: 1.5rem;
  font-size: 0.875rem; /* text-sm */
  color: black;
  margin-bottom: 1rem;
}

/* Active nav item style */
.nav-item.active {
  color: var(--primaryColor);
}

/* Style for the last nav item (e.g., button appearance) */
.nav-item:last-child {
  background-color: var(--color-primary);
  color: var(--color-white);
  padding: 1rem 1.5rem;
  border-radius: 0.5rem;
  max-width: fit-content;
}

/* Menu button - visible only on mobile */
.menu-button {
  width: 2rem;
  height: 2rem;
  display: inline-block;
}

.hero-section {
  width: 100%;
  min-height: 100vh;
  position: relative;
  margin-top: 5rem; /* Adjust for header space */
  overflow: hidden; /* Prevents the image from extending beyond this section */
}

.overlay {
  position: absolute;
  inset: 0;
  height: 100%;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.5));
  z-index: 10;
}

.hero-image {
  width: 100%;
  height: 100%; /* Ensures the image fills the section without overflowing */
  object-fit: cover; /* Ensures the image covers the section properly */
  position: absolute;
  top: 0;
  left: 0;
}

.content-container {
  position: absolute;
  top: 0;
  inset: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 20;
}

.text-container {
  width: 90%;
  max-width: 80%;
}

.hero-text {
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 1.5;
  color: white;
  text-align: left;
}

.highlight {
  color: var(--secondaryColor);
}

.button-container {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  margin-top: 1rem;
}

.download-button {
  display: flex;
  align-items: center;
  background-color: black;
  padding: 0.2rem 0.5rem;
  border: 1px solid white;
  border-radius: 0.5rem;
  height: 2.5rem;
  gap: 0.5rem;
  width: fit-content;
}

.download-button__icon {
  width: 1rem;
  height: 1rem;
}

.download-button__text {
  margin-left: 0.5rem;
  text-align: left;
}

.download-button__title1 {
  font-size: 0.625rem;
  color: white;
  line-height: 1;
}

.download-button__title2 {
  font-size: 0.875rem;
  color: white;
  font-weight: bold;
}

.services-section {
  position: relative;
  overflow: hidden;
}

.services-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1; /* Position behind content */
}

.services-content {
  position: relative;
  padding: 4rem 2rem;
}

.services-container {
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
}

.services-title {
  color: var(--color-secondary);
  font-weight: bold;
  font-size: 1.25rem;
  margin-top: 2rem;
}

.services-subtitle {
  font-weight: bold;
  font-size: 2rem;
  margin-top: 1rem;
}

.services-items {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1.5rem;
  margin-top: 2rem;
}

.service-card {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  width: 100%;
  max-width: 300px;
  text-align: left;
}

.service-card-image {
  position: relative;
}

.service-image {
  width: 100%;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.service-icon {
  position: absolute;
  bottom: -20px;
  right: 20px;
  background-color: #c5ce38;
  border-radius: 8px;
  padding: 0.5rem;
}

.service-card-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #000;
  margin-top: 2rem;
  padding: 0 1rem;
}

.service-card-description {
  color: #878680;
  font-weight: 500;
  margin-top: 0.5rem;
  padding: 0 1rem;
}

.register-grocery-store {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  max-width: 1200px;
  margin: 0 auto;
  gap: 3rem;
  padding: 5rem 0;
}

.store-image-container {
  width: 100%;
  max-width: 40%;
}

.store-image {
  width: 100%;
  border-radius: 0.5rem;
}

.store-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  max-width: 390px;
}

.store-title {
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 1.5;
}

.store-title .highlight {
  color: var(--color-secondary);
}

.store-description {
  margin-top: 1.5rem;
  font-size: 0.875rem;
}

.register-button1 {
  background-color: var(--color-primary);
  color: var(--color-white);
  padding: 0.7rem 1rem;
  border-radius: 0.3rem;
  margin-top: 2.5rem;
  max-width: 145px;
}

.register-wholesale-company {
  display: flex;
  flex-direction: row;
  border-top: 1px solid var(--color-secondary);
  border-bottom: 1px solid var(--color-secondary);
  justify-content: space-evenly;
  max-width: 1200px;
  margin: 0 auto;
  gap: 3rem;
  padding: 5rem 0;
}

@media (max-width: 720px) {
  .register-grocery-store,
  .register-wholesale-company {
    flex-direction: column;
    justify-content: space-around;
    text-align: center;
    max-width: 1200px;
  }
  .store-image-container {
    width: 100%;
    min-width: 80%;
    margin: auto;
  }

  .store-info {
    min-width: 80%;
    margin: auto;
  }
}

.joinus-section {
  background-image: url("../static/joinNow.png");
  color: white;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: auto;
  min-height: fit-content;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5rem 0;
}

.overlay {
  position: absolute;
  inset: 0;
  background-color: var(--color-tertiary);
  opacity: 0.1;
  z-index: 1;
}

.content {
  position: relative;
  text-align: center;
  z-index: 2;
}

.title {
  font-size: 1.25rem;
  font-weight: bold;
}

.join-button1 {
  background-color: var(--color-primary);
  color: var(--color-white);
  padding: 0.7rem 1rem;
  border-radius: 0.3rem;
  margin-top: 2.5rem;
  max-width: 145px;
}

.customer-section-container {
  max-width: 1200px;
  margin: auto;
  padding: 5rem 0;
}

.customer-section-inner {
  width: 80%;
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  align-items: center;
  gap: 2.5rem;
  margin: 0 auto;
}

@media (min-width: 1024px) {
  .customer-section-inner {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    gap: 5rem;
  }
}

.customer-image-wrapper {
  width: 100%;
}

@media (min-width: 1024px) {
  .customer-image-wrapper {
    width: 50%;
  }
}

.customer-section-image {
  width: 100%;
  border-radius: 0.5rem;
}

.customer-content-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media (min-width: 1024px) {
  .customer-content-wrapper {
    width: 50%;
  }
}

.customer-title {
  font-size: 2rem;
  font-weight: 900;
  color: black;
  line-height: 2.4rem;
  text-align: left;
}

.customer-highlight {
  color: var(--color-secondary);
}

.customer-description {
  margin-top: 2.5rem;
  text-align: left;
  font-weight: 500;
}

.customer-button {
  width: fit-content;
  background-color: white;
  border: 2px solid var(--color-primary);
  border-radius: 0.5rem;
  padding: 0.5rem 1.5rem;
  margin-top: 2.5rem;
}

.customer-link {
  color: black;
  font-weight: 600;
  text-decoration: none;
}

.download-section-container {
  width: 100%;
  padding: 5rem 0;
}

.download-section-inner {
  max-width: 1200px;
  height: 180px;
  background-image: url("../static/downloadbg.png"); /* Update with actual background path */
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 2.5rem;
  padding: 5rem 2.5rem;
  margin: 0 auto;
  border-radius: 1rem;
}

.footer-section-container {
  width: 100%;
  background-color: var(--color-tertiary-shade1);
  padding-top: 5rem;
  display: flex;
  flex-direction: column;
}

.footer-content {
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
}

@media (min-width: 768px) {
  .footer-content {
    flex-direction: row;
    justify-content: space-between;
    gap: 0;
  }
}

/* Left Content */
.footer-left-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

@media (min-width: 768px) {
  .footer-left-content {
    align-items: flex-start;
    width: 33%;
  }
}

.footer-logo {
  height: 3.5rem;
  width: 3.5rem;
}

.footer-logo-img {
  height: 100%;
  width: auto;
}

.footer-description {
  font-size: 0.9375rem;
  color: var(--color-tertiary);
  margin-top: 2.5rem;
  text-align: center;
}

@media (min-width: 768px) {
  .footer-description {
    text-align: left;
  }
}
.footer-socials {
  display: flex;
  gap: 1.5rem;
  margin-top: 2.5rem;
  justify-content: flex-start; /* Align icons to the start (left) */
  width: 100%;
}

@media (max-width: 768px) {
  .footer-socials {
    justify-content: center;
  }
}
.social-icon {
  width: 2rem;
  height: 2rem;
  background-color: var(--color-tertiary);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center; /* Centers the icon within each circle */
  padding: 0.25rem; /* Adjust padding if needed */
}

/* Middle Content */
.footer-middle-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1200px;
}

@media (min-width: 768px) {
  .footer-middle-content {
    align-items: flex-start;
    width: 33%;
  }
}

.section-title-text {
  color: white;
  font-size: 1.25rem;
  font-weight: bold;
}

.title-decorator {
  display: flex;
  gap: 0.5rem;
  margin-top: 0.5rem;
}

.decor-line {
  width: 80%;
  height: 0.5rem;
  background-color: var(--color-primary);
  border-radius: 0.25rem;
}

.decor-circle {
  width: 0.5rem;
  height: 0.5rem;
  background-color: var(--color-primary);
  border-radius: 50%;
}

.explore-links {
  margin-top: 1rem;
}

.explore-link {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
}

.link-text {
  color: var(--color-tertiary);
  font-size: 0.9375rem;
}

/* Right Content */
.footer-right-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

@media (min-width: 768px) {
  .footer-right-content {
    align-items: flex-start;
    width: 33%;
  }
}

.contact-links {
  margin-top: 1rem;
}

.contact-link {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
}

/* Footer Note */
.footer-note {
  background-color: var(--color-tertiary);
  width: 100%;
  padding: 2.5rem 0;
  margin-top: 2.5rem;
}

.footer-note-content {
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--color-white);
}

@media (min-width: 768px) {
  .footer-note-content {
    flex-direction: row;
    justify-content: space-between;
  }
}

.terms-link {
  color: var(--color-white);
  margin: 0 0.5rem;
}

@media (min-width: 768px) {
  .download-section-inner {
    width: 100%;
    height: 422px;
    padding: 5rem 5rem;
  }
}

.download-text-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.download-title {
  color: white;
  text-align: left;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.5rem;
}

@media (min-width: 768px) {
  .download-title {
    font-size: 1.875rem;
    line-height: 2.5rem;
  }
}

@media (min-width: 1024px) {
  .download-title {
    font-size: 3.125rem;
    line-height: 4.2rem;
  }
}

.download-button-group {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  margin-top: 1rem;
}

@media (min-width: 768px) {
  .download-button-group {
    flex-direction: row;
    gap: 1.5rem;
    margin-top: 2.5rem;
  }
}

.download-image-container {
  width: 50%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  position: relative;
}

.phone-large {
  width: 67px;
  margin-top: -6rem;
  z-index: 30;
}

@media (min-width: 768px) {
  .phone-large {
    width: 210px;
    margin-top: -2rem;
  }
}

.phone-lg-image {
  width: 100%;
  height: auto;
  max-height: 422px;
}

.phone-small {
  width: 67px;
  margin-left: -2.5rem;
}

@media (min-width: 768px) {
  .phone-small {
    width: 210px;
    margin-bottom: -0.5rem;
  }
}

.phone-sm-image {
  width: 100%;
  height: auto;
}

@media (min-width: 768px) {
  .title {
    font-size: 1.875rem;
  }
}

@media (min-width: 1024px) {
  .title {
    font-size: 2.5rem;
  }
}

.subtitle {
  font-size: 1rem;
  margin-top: 0.5rem;
}

@media (min-width: 768px) {
  .subtitle {
    font-size: 1.25rem;
    margin-top: 1rem;
  }
}

@media (min-width: 1024px) {
  .subtitle {
    font-size: 1.5rem;
    margin-top: 1.5rem;
  }
}

.join-button {
  margin-top: 1.5rem;
  padding: 0.5rem 1.5rem;
  font-size: 0.875rem;
  background-color: var(--primaryColor);
  color: white;
  border-radius: 0.5rem;
}

@media (min-width: 1024px) {
  .join-button {
    margin-top: 2.5rem;
    font-size: 1rem;
  }
}

/* Responsive Styles */
@media (min-width: 640px) {
  .download-button {
    height: 3.5rem;
  }
  .download-button__icon {
    width: 1.5rem;
    height: 1.5rem;
  }
  .download-button__title1 {
    font-size: 0.75rem;
  }
  .download-button__title2 {
    font-size: 1rem;
  }
}

@media (min-width: 768px) {
  .download-button {
    height: 4rem;
    padding: 0.5rem 1rem;
  }
  .download-button__icon {
    width: 2rem;
    height: 2rem;
  }
  .download-button__title1 {
    font-size: 0.875rem;
  }
  .download-button__title2 {
    font-size: 1.25rem;
  }
}

@media (min-width: 1024px) {
  .download-button {
    height: 5rem;
  }
  .download-button__title1 {
    font-size: 1rem;
  }
  .download-button__title2 {
    font-size: 1.5rem;
  }
}

/* Responsive Styles */
@media (min-width: 768px) {
  .hero-text {
    font-size: 2rem;
    line-height: 2.5rem;
  }
}

@media (min-width: 1024px) {
  .hero-section {
    height: 100vh;
  }

  .text-container {
    width: 60%;
  }

  .hero-text {
    font-size: 2.5rem;
    line-height: 3rem;
  }

  .button-container {
    gap: 2rem;
    margin-top: 2rem;
  }
}

/* Desktop Navigation */
@media (min-width: 1024px) {
  .nav {
    position: static;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end; /* Align items to the right */
    height: auto;
    width: auto;
    padding-top: 0;
  }
  .nav-item {
    margin-bottom: 0;
  }
  .menu-button {
    display: none;
  }
}

/* Mobile adjustments */
@media (max-width: 768px) {
  .headerSubContainer {
    width: 95%;
  }
}

.navcontainer {
  display: flex;
  /* flex-direction: row; */
  justify-content: space-between;
  margin: 0 5%;
  align-items: center;
}

.lbutton {
  padding: 10px;
  color: var(--color-white);
  background-color: var(--color-secondary);
  text-decoration: none;
  font-weight: bold;
  border-radius: 5px;
  margin: 4px 4px 0px 0;
}

.glogo {
  height: 200px;
}

.nav-buttonContainer {
  display: flex;
  justify-content: space-between;
  width: 25%;
}

.nbutton {
  color: var(--color-tertiary);
}

.comp_logo {
  height: 200px;
}

.topBodyConatainer {
}

.topBodyConatainer1 {
  display: flex;
  justify-content: space-between;
  margin: auto;
}

.sideImage {
  width: 200px;
  height: fit-content;
  align-self: center;
}

.containerTop {
  margin: auto;
  align-self: center;
}

.topText {
  font-size: 80px;
  align-self: center;
  text-shadow: 5px 5px var(--color-tertiary-tint2);
}

.appContainer {
  display: flex;
  color: var(--color-white);
  width: 40%;
  margin: auto;
  justify-content: space-between;
}

.eachIcon {
  background-color: var(--color-primary);
  border-radius: 40px;
  padding: 10px 60px;
  display: flex;
  justify-content: space-between;
  color: var(--color-white);
  align-items: center;
  font-weight: bold;
  margin: 50px auto;
}

.eachIcon > span {
  color: var(--color-white);
}

.schedule {
  margin: 20px 20%;
  flex-wrap: 1;
  display: flex;
}

.schedule23 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: 1;
}

.schedule1 {
  display: flex;
  flex-direction: column;
  background-image: linear-gradient(
      /* rgba(255, 255, 255, 0.85),  */ /* rgba(255,164,0, 0.45), */
        rgba(57, 120, 70, 0.45),
      rgba(0, 0, 0, 0.95)
    ),
    url("../static/grocshop.jpg");
  background-size: cover; /* <------ */
  background-repeat: no-repeat;
  background-position: center center;
  color: var(--color-white);
  margin: 0 20px;
  padding: 20px;
  text-align: left;
  border-radius: 10px;
}

.schedule1 > h4 {
  margin-top: 350px;
  padding: 0 40px;
  font-weight: bold;
  font-size: xx-large;
}

.schedule1 > p {
  /* margin-top: 200px; */
  padding: 0 40px;
  font-weight: bold;
}

.schedule2 {
  display: flex;
  flex-direction: column;
  background-image: linear-gradient(
      /* rgba(255, 255, 255, 0.85),  */ /* rgba(255,164,0, 0.45), */
        rgba(57, 120, 70, 0.45),
      rgba(0, 0, 0, 0.95)
    ),
    url("../static/lfarm.jpg");
  background-size: cover; /* <------ */
  background-repeat: no-repeat;
  background-position: center center;
  color: var(--color-white);
  margin: 0 20px;
  padding: 20px;
  text-align: left;
  border-radius: 10px;
  height: 48%;
}

.schedule2 > h4,
.schedule3 > h4 {
  margin-top: 90px;
  padding: 0 40px;
  font-weight: bold;
  font-size: xx-large;
}

.schedule2 > p,
.schedule3 > p {
  /* margin-top: 90px; */
  padding: 0 40px;
  font-weight: bold;
  /* font-size: xx-large; */
}

.schedule3 {
  display: flex;
  flex-direction: column;
  background-image: linear-gradient(
      /* rgba(255, 255, 255, 0.85),  */ /* rgba(255,164,0, 0.45), */
        rgba(57, 120, 70, 0.45),
      rgba(0, 0, 0, 0.95)
    ),
    url("../static/llog.jpg");
  background-size: cover; /* <------ */
  background-repeat: no-repeat;
  background-position: center center;
  color: var(--color-white);
  margin: 0 20px;
  padding: 20px;
  text-align: left;
  border-radius: 10px;
  height: 48%;
}

.regStore {
  background-color: var(--color-secondary);
  display: flex;
  align-content: center;
  justify-content: space-between;
  margin: auto;
}

.money {
  margin: 5% 10%;
  width: 30%;
  border-radius: 20px;
}

.regstore1 {
  margin: 5% 10%;
  width: 30%;
  text-align: start;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.regstore1 > p {
  margin-bottom: 20px;
}

.cbutton {
  background-color: var(--color-primary);
  padding: 10px 20px;
  border-radius: 10px;
  color: var(--color-white);
  font-weight: bold;
}

.reglogistics,
.reglogistics1 {
  display: flex;
  align-content: center;
  justify-content: space-between;
  margin: auto;
}

.together {
  background-color: var(--color-secondary);
  margin: auto;
  padding: 50px;
  color: var(--color-white);
}

.footer {
  background-color: var(--color-tertiary);
  display: flex;
  justify-content: space-between;
  padding: 40px;
  align-content: center;
  align-items: center;
}
.footer1 {
  display: flex;
}
.footer2 {
  color: var(--color-white);
  margin: 2px;
}

.appContainer2 {
  display: flex;
  flex-direction: column;
  color: var(--color-white);
  margin: 1px;
}

.eachIcon1 {
  background-color: var(--color-primary);
  border-radius: 40px;
  padding: 10px 60px;
  display: flex;
  justify-content: space-between;
  color: var(--color-white);
  align-items: center;
  font-weight: bold;
  margin: 10px auto;
}

.footer2 > h3,
.footer > .appContainer {
  margin: auto;
  padding: 0;
}

.footer3 {
  color: var(--color-white);
  align-items: center;
}

.Mmenuitem1 {
  display: flex;
  padding: 10px;
}
.Mmenuicon1 {
  color: var(--color-white);
}

.Mmenuident1 {
  color: var(--color-white);
  padding-left: 5px;
}

/* For mobile view */
@media (max-width: 1100px) {
  .navcontainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* padding: 0 5%; */
  }

  .comp_logo {
    height: 100px; /* Adjust the height as needed for mobile view */
    margin-right: auto; /* Push the logo to the right */
  }

  .nav-buttonContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .lbutton,
  .nbutton {
    padding: 5px;
    color: var(--color-white);
    background-color: var(--color-secondary);
    text-decoration: none;
    font-weight: bold;
    border-radius: 5px;
    font-size: 10px;
    margin: 4px 0; /* Adjust margin as needed for spacing between buttons */
  }

  /* Hide "About Us" and "Contact Us" buttons on mobile */
  .nbutton {
    display: none;
  }

  .sideImage {
    width: 80px;
    height: 80px;
  }

  .topText {
    text-shadow: 3px 3px var(--color-tertiary-tint2);
    font-size: 36px;
  }

  .appContainer {
    width: 80%;
  }

  .together > .appContainer {
    width: 100%;
  }

  .eachIcon {
    padding: 3px 13px;
    font-size: 12px;
    font-weight: bold;
    margin: 20px auto;
  }
  .schedule {
    /* padding: 10px; */
    margin: 2% 5%;
  }

  .schedule1 {
    margin: 5px;
    padding-left: 0px;
    border-radius: 10px;
  }

  .schedule1 > h4 {
    margin-top: 270px;
    font-size: larger;
    width: 90%;
    padding: 5px 10px;
  }

  .schedule1 > p {
    font-size: small;
    padding: 5px 10px;
  }
  .schedule2,
  .schedule3 {
    margin: 5px;
    padding-left: 0px;
    border-radius: 10px;
  }

  .schedule2 > h4,
  .schedule3 > h4 {
    margin-top: 70px;
    font-size: larger;
    width: 90%;
    padding: 5px 10px;
  }

  .schedule2 > p,
  .schedule3 > p {
    font-size: small;
    padding: 5px 10px;
  }

  .reglogistics > .regstore1 {
    padding-left: 30px;
  }

  .money {
    width: 40%;
    height: 40%;
    align-self: center;
    margin: o;
  }
  .regstore1 {
    margin: 5% 10% 5% 0;
    width: 60%;
    padding: 0;
    /* margin: 0; */
    /* justify-content: space-around; */
  }
  .regstore1 > h1 {
    font-weight: bold;
    font-size: 15px;
    width: 40%;
  }
  .regstore1 > p {
    font-weight: normal;
    font-size: 12px;
  }

  .cbutton {
    padding: 3px 5px;
    border-radius: 5px;
    color: var(--color-white);
    font-weight: bold;
    font-size: 10px;
  }
  .footer {
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 768px) {
  .will {
    font-size: 2rem;
  }

  .main_logo {
    /* width: 10%; */
    margin: 1px;
  }
  .landin-main {
    height: 200vh;
  }
}
.layer {
  /* max-width: 1100px; */
  /* display: flex ; */
  margin: 0 auto;
}

.main_logo {
  width: 20%;
  margin: -50px;
}

.will {
  /* color: rgba(57,120,70, 1); */
  font-size: 3rem;
  margin-bottom: 1px;
  font-family: "Times New Roman", Times, serif;
  color: var(--color-tertiary);
}
.wewill {
  color: var(--color-white);
}
.will1 {
  /* color: rgba(57,120,70, 1); */
  margin-top: 40px;
  margin-bottom: 1px;
  color: var(--color-tertiary);
}

Link {
  text-decoration: none;
}

.socials {
  display: flex;
  width: 150px;
  justify-content: space-between;
  font-size: 40px;
  align-items: center;
  margin: auto;
  text-decoration: none;
}

.socials > a {
  color: var(--color-tertiary);
}

.loading-spinner {
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 6px solid #ffa400;
  border-top: 6px solid #ffffff;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 768px) {
  .will {
    font-size: 2rem;
  }

  .main_logo {
    /* width: 10%; */
    margin: 1px;
  }
  .landin-main {
    height: 200vh;
  }
}
