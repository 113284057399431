* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
:root {
  --purple-color: rgb(200, 121, 200);
  --grey-color: rgb(156, 150, 150);
  /* --color-primary: rgb(26, 119, 26); */
}

.profile1 {
  display: flex;
  /* flex-direction: column; */ /* Remove this line to make them appear side by side */
  width: var(--container-width-sm);
  justify-content: space-around;
  margin: auto;
  color: var(--color-tertiary);
  border: 10px solid var(--color-white);
  border-radius: 10px;
}

.orderDetail {
  display: flex;
  flex-direction: row;
  margin: auto;
  justify-content: center;
}
.orderDetailContainer {
  /* border: 1px solid red; */
  height: 80%;
  width: 100%;
  display: flex;
}
.order-details {
  height: 80%;
  width: 60%;
}
.order-details-box {
  border: 1px solid grey;
  height: 28rem;
  border-radius: 5px;
}
.heading {
  display: flex;
  justify-content: space-between;
  padding: 1.3rem 1rem 0.8rem;
}
.heading button {
  padding: 0.3rem 0.5rem;
  border-radius: 5px;
  color: var(--color-white);
  background-color: var(--color-primary-shade3);
  font-weight: 600;
}
.order-details-item-container {
  height: 20rem;
  border-bottom: 1px solid var(--grey-color);
  padding: 1rem;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  overflow: auto;
}
.order-details-item {
  display: flex;
  width: 100%;
  /* justify-content: space-between; */

  position: relative;
}
.order-details-item-img {
  height: 4rem;
  width: 6rem;
  border-radius: 10px;
  /* border: 1px solid red; */
  margin-right: 1rem;
}
.order-details-teller-img {
  height: 4rem;
  width: 6rem;
  border-radius: 10px;
  /* border: 1px solid red; */
  margin-right: 1rem;
}
.order-details-item-img img {
  height: 100%;
  width: 100%;
  object-position: cover;
  object-fit: center;
}
.order-details-item-name {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.order-details-item-quantity {
  position: absolute;
  right: 10rem;
}
.order-details-item-total {
  position: absolute;
  right: 0rem;
}
.order-details-subtotal {
  display: flex;
  margin-top: 1rem;
  position: relative;
  padding: 0 1rem;
  width: 100%;
}
.order-details-subtotal h3 {
  margin-right: 4rem;
}
.order-details-subtotal-text {
  position: absolute;
  right: 1rem;
}
.order-details-logistics {
  display: flex;
  justify-content: space-between;
  padding: 0 1rem;
  margin-top: 0.5rem;
  border-bottom: 1px solid var(--grey-color);
}
.order-details-total {
  display: flex;
  margin-top: 1.5rem;
  justify-content: space-between;
  padding: 0 1rem;
}
.order-details-delivery {
  height: 9rem;
  width: 100%;
  border: 1px solid var(--grey-color);
  text-align: left;
  padding: 1rem;
  border-radius: 10px;
  margin-top: 1rem;
}
.order-details-delivery-location {
  width: 100%;
  display: flex;
  margin-top: 1rem;
  justify-content: space-between;
  text-align: left;
}
.order-details-btns {
  display: flex;
  justify-content: space-between;
  margin-top: 1.5rem;
}
.order-details-btn {
  width: 48%;
  height: 3rem;
  padding: 1rem;
  background-color: var(--color-primary);
  border-radius: 10px;
  text-align: left;
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  align-items: center;
}
.order-details-btn p {
  font-size: 12px;
  color: var(--color-white);
}
.order-details-btn2 {
  width: 48%;
  height: 3rem;
  padding: 1rem;
  background-color: var(--color-primary);
  border-radius: 10px;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;

  color: var(--color-white);
}

.order-details-btn3 {
  width: 48%;
  height: 3rem;
  padding: 1rem;
  background-color: var(--color-secondary);
  border-radius: 10px;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  font-weight: bold;
  color: var(--color-white);
}
.order-detail-container-history {
  margin-left: 2rem;
  height: 90vh;
  /* border: 1px solid var(--color-primary-shade1); */
  width: 35%;
  padding-top: 1.5rem;
}
.order-detail-container-history-head {
  text-align: left;
  padding-bottom: 1rem;
}
.order-detail-container-history-box {
  height: 25.4rem;
  border: 1px solid var(--color-tertiary-shade3);
  border-radius: 5px;
  overflow-y: auto;
}

.btn-red {
  background-color: red;
  color: white;
}

.btn-blue {
  background-color: blue;
  color: white;
}

.btn-green {
  background-color: green;
  color: white;
}

.btn-yellow {
  background-color: yellow;
  color: black;
}

.btn-brown {
  background-color: brown;
  color: white;
}

.btn-black {
  background-color: black;
  color: white;
}

.order-detail-container-history-box-id {
  /* display: flex; */
  position: relative;
  align-items: center;
  padding: 0.2rem 0.4rem;
  margin-top: 0.5rem;
  border-bottom: 1px solid var(--color-tertiary-shade3);
  /* justify-content: space-between; */
  height: 8rem;
}
.order-detail-container-history-box-id-content {
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 0.2rem;
}
.order-detail-container-history-box-id-content h5 {
  font-weight: 600;
}
.order-detail-container-history-box-id-content p {
  font-size: 14px;
}
.order-detail-container-history-box-id-content-location {
  font-size: 15px;
  font-weight: 600;
}
.order-detail-container-history-box-id-status {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.order-detail-container-history-box-id-status h5:nth-child(1) {
  color: var(--color-primary-shade1);
}
.order-detail-container-history-box-id-status h5:nth-child(2) {
  color: var(--color-danger-variant);
}
.order-detail-container-history-box-id-status .bx {
  font-size: 0.8rem;
  color: var(--color-danger-variant);
  font-weight: 600;
}
.order-detail-container-history-box-id-price {
  position: absolute;
  right: 0.4rem;
  bottom: 3rem;
}
.order-detail-container-history-box-id-price span {
  color: var(--color-primary-shade1);
}
.order-detail-container-history-shipping {
  margin-top: 1.5rem;
  padding: 1rem;
  height: 13.5rem;
  border-radius: 5px;
  border: 1px solid var(--color-tertiary-shade3);
  width: 100%;
  font-weight: 600;
  position: relative;
  text-align: left;
}
.order-detail-container-history-shipping-heading {
  margin-bottom: 0.7rem;
}
.order-detail-container-history-shipping h4 {
  font-size: 16px;
  font-weight: 600;
}
.order-detail-container-history-shipping p {
  font-size: 14px;
}
.order-detail-container-history-shipping .bx {
  font-size: 7rem;
  color: var(--color-tertiary);
  position: absolute;
  right: 5rem;
}
.mobile-view {
  display: none;
}

.full-screen-image {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure the image is above other elements */
}

.full-screen-image img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  cursor: pointer; /* Change cursor to pointer */
}
/* desktop view */
@media (max-width: 75em) {
  .orderDetailContainer {
    border: 1px solid blue;
  }
  .order-details-item-name {
    font-size: 12px;
  }
  .order-details-item-quantity {
    right: 4rem;
    font-size: 12px;
  }

  .order-details-btns {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-top: 1.5rem;
    align-items: center;
  }
  .order-details-item-total {
    font-size: 12px;
  }
  .order-details-subtotal {
    font-size: 13px;
  }
  .order-details-logistics {
    font-size: 13px;
  }
  .order-details-total {
    font-size: 13px;
  }
  .order-details-delivery {
    font-size: 90%;
  }
  .order-detail-container-history {
    width: 40%;
    margin-left: 1rem;
  }
  .order-detail-container-history-box-id-content h4 {
    font-size: 13px;
  }
  .order-detail-container-history-box-id-price {
    font-size: 14px;
    bottom: 3.5rem;
  }
}
@media (max-width: 56.25em) {
  .orderDetailContainer {
    border: 1px solid brown;
  }

  .order-details-btns {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-top: 1.5rem;
  }
}
@media (max-width: 40.25em) {
  .order-detail-container-history {
    display: none;
  }
  .order-details {
    display: none;
  }

  .order-details-btns {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-top: 1.5rem;
  }
  .mobile-view {
    display: block;
    /* border: 1px solid red; */
    height: 100%;
    width: 100%;
  }
  .order-detail-mobile-body {
    /* padding: 2rem 1rem 1rem; */
  }
  .order-detail-mobile-reference {
    /* height: 3rem; */
    border-bottom: 1px solid var(--color-tertiary-shade3);
    position: relative;
    padding-bottom: 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 80%;
  }
  .order-detail-mobile-reference-date {
    color: var(--color-tertiary-shade3);
  }
  .order-detail-mobile-reference-label {
    color: var(--color-tertiary-shade3);
    margin-top: 0.5rem;
  }
  .order-detail-mobile-reference-status {
    color: var(--color-primary-shade2);
    position: absolute;
    top: 0;
    right: 0rem;
  }
  .order-detail-mobile-order-box {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    row-gap: 0.2rem;
    font-size: 80%;
    padding-top: 1.2rem;
    /* padding-bottom: 1.2rem; */
    /* border-bottom: 1px solid var(--color-tertiary-shade3); */
    height: 10rem;
    overflow-y: auto;
  }
  .order-detail-mobile-order-box-items {
    display: flex;
    justify-content: space-between;
  }
  .order-detail-mobile-order-box-items-name {
    text-align: left;
  }
  .order-detail-mobile-order-box-items-name-count {
    color: var(--color-tertiary-shade3);
  }
  .order-detail-mobile-order-box-items-price span {
    color: var(--color-primary-shade2);
  }
  .order-detail-mobile-total {
    margin-top: 1.5rem;
    border-top: 1px solid var(--color-tertiary-shade3);
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid var(--color-tertiary-shade3);
    font-size: 80%;
  }
  .order-detail-mobile-total div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0.5rem;
  }
  .order-detail-mobile-total div span {
    color: var(--color-primary-shade2);
  }
  .order-detail-mobile-item-total h3 {
    color: var(--color-tertiary-shade3);
  }
  .order-detail-mobile-charges h3 {
    color: var(--color-tertiary-shade3);
  }
  .order-detail-mobile-support {
    display: flex;
    background-color: var(--color-white);
    position: relative;
    padding: 0.5rem;
    align-items: center;
    border-radius: 10px;
    margin-top: 1.5rem;
    font-size: 80%;
  }
  .order-detail-mobile-support p {
    font-weight: 600;
    margin-left: 0.5rem;
  }
  .order-detail-mobile-support-link {
    position: absolute;
    right: 1rem;
    color: var(--color-secondary-shade1);
  }
  .order-detail-mobile-reorder {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--color-white);
    height: 3.5rem;
    border-radius: 10px;
    font-weight: 600;
    font-size: 80%;
    color: var(--color-primary-shade1);
    margin-top: 1.7rem;
  }
}
